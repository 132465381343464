
import { Component, Vue } from 'vue-property-decorator';
import Preview from '@/components/project/template/Preview.vue';
import { IQuestionValue } from '@/interface/survey/question';
import {IChecklist} from "@/interface/admin/checklist";


@Component({
  components: { Preview },
})
export default class PreviewSurvey extends Vue {
  question: IQuestionValue[] = [];
  questionTitle: string;
  sNum: string = this.$route.params.sNum;
  token: string = this.$route.params.token;
  loading: boolean = true;
  checklistInfo: IChecklist;
  isPaid = false;
  isRefunded = false;
  isAdmin = false;

  async created(): Promise<void> {
    await this.setAdminToken();
    await this.init();
  }

  setAdminToken(): void {
    this.$store.commit('setToken', this.token);
  }

  async init(): Promise<void> {
    this.loading = true;
    try {
      const { data } = await this.axios.get(`/admin/project/preview/${+this.sNum}`);
      const { isAdmin } = data;
      this.isAdmin = isAdmin || this.$store.getters.isAdmin;

      if (!isAdmin) {
        setTimeout(() => {
          this.$router.replace('/');
        }, 400);
      } else {
        const { info, checklistInfo } = data;
        const { DATA, TITLE } = info;
        this.question = DATA;
        this.isPaid = info.CONFIG.PAYMENT_INFO;
        this.questionTitle = TITLE;
        this.checklistInfo = checklistInfo;
        this.isRefunded = info.CONFIG.LINK_STATUS === 4;
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }
}

